import moment from 'moment/moment';
import { Link, useSearchParams } from 'react-router-dom';
import { Building2 } from 'lucide-react';
import { Error } from '@/components/ui/error';
import { Loading } from '@/components/ui/loading';
import { useCompanies } from '@/data/company';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { Empty, EmptyIcon, EmptyTitle, EmptyDescription } from '@/components/ui/empty';

const List = () => {
  const [searchParams] = useSearchParams();
  const companies = useCompanies(searchParams.get('q'), searchParams.get('disabled') === 'true');

  if (companies.isLoading && !companies.data) {
    return <Loading />;
  }

  if (companies.error) {
    return <Error message="An error occurred loading companies" />;
  }

  if (companies.data.query.length === 0) {
    return (
      <Empty>
        <EmptyIcon>
          <Building2 />
        </EmptyIcon>
        <EmptyTitle>No Companies Found</EmptyTitle>
        <EmptyDescription>No companies found with the given search criteria</EmptyDescription>
      </Empty>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Logo</TableHead>
          <TableHead>Company</TableHead>
          <TableHead>Created</TableHead>
          <TableHead>Estimates</TableHead>
          <TableHead>Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {companies.data.query.map((companyUid) => {
          const company = companies.data.content[companyUid];
          return (
            <TableRow key={companyUid}>
              <TableCell className="w-[60px] p-0">
                <Link to={`/company/${company.uid}`} className="p-4 block">
                  <div className="border rounded w-[60px] h-[40px] bg-background flex items-center justify-center p-1">
                    {company.logo_url && <img src={company.logo_url} alt={company.company_name} className="max-w-[50px] max-h-[30px] object-cover" loading="lazy" />}
                    {!company.logo_url && <div className="text-[8px] uppercase text-secondary">No Logo</div>}
                  </div>
                </Link>
              </TableCell>
              <TableCell className="font-medium p-0">
                <Link to={`/company/${company.uid}`} className="p-4 block">
                  {company.company_name}
                </Link>
              </TableCell>

              <TableCell className="p-0">
                <Link to={`/company/${company.uid}`} className="p-4 block">
                  {moment(company.create_ts).format('MMM Do, YYYY')}
                </Link>
              </TableCell>
              <TableCell className="p-0">
                <Link to={`/company/${company.uid}`} className="p-4 flex gap-2">
                  {company.is_v1_estimate && <Badge variant="secondary">V1</Badge>}
                  {company.is_v2_estimate && <Badge variant="secondary">V2</Badge>}
                </Link>
              </TableCell>
              <TableCell className="p-0">
                <Link to={`/company/${company.uid}`} className="p-4 block">
                  <div className="flex gap-2">
                    {company.disabled && <Badge variant="destructive">Disabled</Badge>}
                    {!company.deleted && !company.disabled && <Badge variant="secondary">Active</Badge>}
                  </div>
                </Link>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default List;
